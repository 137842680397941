<template>
  <v-menu v-model="menu" :close-on-content-click="false" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <span v-on="on">{{ $t("consent-form.color_picker") }}</span>
    </template>

    <v-card width="100">
      <v-card-text>
        <v-row justify="center">
          <v-col cols="5" v-for="key in Object.keys(colors)" :key="key">
            <v-btn @click="select(key)" icon>
              <v-icon :color="colors[key].background">mdi-circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ColorPicker",
  mounted() {
    this.fetchColors();
  },
  props: ["tattooer"],
  data() {
    return { colors: {}, menu: false };
  },
  methods: {
    ...mapActions("oauth", ["getColors", "setColor"]),
    fetchColors() {
      this.getColors().then((data) => {
        this.colors = data;
      });
    },
    select(color) {
      this.setColor({
        color,
        tattooer_id: this.tattooer.id,
      }).then((data) => {
        //console.log(data);
      });
    },
  },
};
</script>
<style scoped lang="sass">
.v-menu__content .v-card
  margin: 0
</style>